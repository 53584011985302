import React, { LegacyRef } from "react";

import { AiFillEdit, AiFillDelete } from "react-icons/ai";

type Props = {
    toggleModal?: () => void;
    children: React.ReactNode;
    onDelete?: () => void;
    className?: string;
    ref: LegacyRef<HTMLDivElement> | undefined;
    // elemetn || null
};

const EditWrapper = ({
    toggleModal,
    children,
    onDelete,
    className,
    ref,
}: Props) => {
    return (
        <div
            ref={ref}
            tabIndex={0}
            className={`text-left flex relative group w-fit ${className}`}
        >
            <div
                className="cursor-pointer pr-6 w-full"
                onClick={() => toggleModal && toggleModal()}
            >
                {children}
            </div>
            <div className="absolute top-0 right-0 translate-x-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:translate-x-0 transition-all">
                {toggleModal && (
                    <button className="block">
                        <AiFillEdit onClick={() => toggleModal()} />
                    </button>
                )}
                {onDelete && (
                    <button
                        onClick={() => onDelete()}
                        className="block mt-2 text-red-500"
                    >
                        <AiFillDelete />
                    </button>
                )}
            </div>
        </div>
    );
};

export default EditWrapper;
