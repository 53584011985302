import React, { useState } from "react";

import { MEDIA_URL } from "../../utils/constants";

import Dropzone from "../Dropzone";
import EditWrapper from "../EditWrapper";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

import DraftEditor from "../DraftEditor";
import ReactQuillEditor from "../ReactQuillEditor";

type Props = {
    blog_id: number | string;
    image: string;
    title: string;
    description: string;
    handleUpdate: (
        blog_id: string | number,
        title?: string,
        description?: string,
        image?: string
    ) => void;
};

const BlogCard = ({
    description,
    image,
    blog_id,
    title,
    handleUpdate,
}: Props) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [uploadedImg, setUploadedImg] = useState<string>(image);
    const [descriptionValue, setDescriptionValue] = useState(description || "");

    const toggleEditModal = () => {
        setEditModalOpen((p) => !p);
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen((p) => !p);
    };

    const handleDeleteSubmit = () => {
        handleUpdate(blog_id);
        toggleDeleteModal();
    };

    const descriptionChangeHandler = (v: any) => {
        setDescriptionValue(v);
    };

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        handleUpdate(blog_id, data.title, descriptionValue, uploadedImg);
        toggleEditModal();
    };

    return (
        <>
            <Modal isOpen={deleteModalOpen} toggleModal={toggleDeleteModal}>
                <form className="mt-6" onSubmit={handleDeleteSubmit}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete it?
                    </h4>
                    <div className="flex space-x-4">
                        <Button
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-auto mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                size="4xl"
                isOpen={editModalOpen}
                toggleModal={toggleEditModal}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={image}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    {title && (
                        <div className="input-row">
                            <input
                                name="title"
                                type="text"
                                placeholder="Title *"
                                defaultValue={title}
                                required
                            />
                        </div>
                    )}

                    {description && (
                        <div className="input-row">
                            {/* <DraftEditor
                                default={description}
                                onChange={descriptionChangeHandler}
                            /> */}

                            <ReactQuillEditor
                                default={description}
                                onChange={descriptionChangeHandler}
                            />
                            {/* <textarea
                                name="description"
                                placeholder="Description *"
                                defaultValue={description}
                                rows={10}
                                required
                            /> */}
                        </div>
                    )}

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleEditModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <EditWrapper
                toggleModal={toggleEditModal}
                onDelete={toggleDeleteModal}
                ref={undefined}
            >
                <div className="">
                    <img
                        src={MEDIA_URL + image}
                        alt={image}
                        className="object-cover w-full aspect-square"
                    />
                    <div className="my-2">
                        <span className="my-2 font-bold text-lg">{title}</span>
                        <div
                            className="my-2"
                            dangerouslySetInnerHTML={{
                                __html: `${description
                                    .replace(/(<([^>]+)>)/g, "")
                                    .slice(0, 150)} ...`,
                            }}
                        />
                    </div>
                </div>
            </EditWrapper>
        </>
    );
};

export default BlogCard;
