import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../Components/Dropzone";
import EditWrapper from "../../Components/EditWrapper";
import Loader from "../../Components/Loader/Loader";
import Button from "../../Components/UI/Button";
import Modal from "../../Components/UI/Modal";
import { useUser } from "../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../utils/constants";
import { mobileAxios } from "../../utils/mobileAxios";
import Select from "react-select";

import { Button as MUIButton } from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";
import axios from "axios";

type Props = {};

// decending Comperator
function descendingComparator(a: any, b: any, orderBy: any) {
    if (
        orderBy === "id" ||
        orderBy === "color" ||
        orderBy === "type" ||
        orderBy === "name"
    ) {
        return b[orderBy]
            ?.toLowerCase()
            ?.localeCompare(a[orderBy]?.toLowerCase());
    } else if (orderBy.includes("date")) {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

// ///////////////////////////////////////////////////////////////////////////////////////////////////

// get Comperator
function getComparator(order: any, orderBy: any) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

// head cells for table
const headCells = [
    {
        id: "name",
        label: "Name",
    },
    {
        id: "color",
        label: "Color",
    },
    {
        id: "type",
        label: "Type",
    },
    {
        id: "created_date",
        label: "Created Date",
    },
    {
        id: "action",
        label: "",
    },
];

// ///////////////////////////////////////////////////////////////////////////////////////////////////

// Enhanced Table the actual table and its property types

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

// ///////////////////////////////////////////////////////////////////////////////////////////////////

// the actual component
const CategoriesByCountry = () => {
    const [rows, setRows] = useState<any[]>([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("created_date");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [uploadedImg, setUploadedImg] = useState("");
    const [uploadedAddImg, setUploadedAddImg] = useState("");
    const [addName, setAddName] = useState("");
    const [addColor, setAddColor] = useState("");
    const [addType, setAddType] = useState<any>();
    const [editItemModal, seteditItemModal] = useState<{
        details: any;
    } | null>(null);
    const [addonsModal, setaddonsModal] = useState<string | null | "open">(
        null
    );

    const location = useLocation();
    const { countryId } = location?.state;

    console.log("uploadedImg", uploadedImg);

    const types = [
        { label: "menu", value: "menu" },
        { label: "merchandise", value: "merch" },
    ];
    const { userState } = useUser();
    const params = useParams();

    // console.log(rows, "rows");

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const res = await mobileAxios.get(`categories`);

            const rowsData = res?.data?.map((item: any) => ({
                id: item._id,
                icon: item.icon,
                name: item.name,
                color: item.color,
                type: item.type,
                created_date: item.created_date,
                order: item?.order,
            }));

            rowsData.sort((a: any, b: any) => a.order - b.order);
            setRows(rowsData);
            setIsLoading(false);
        } catch (err: any) {
            toast.error(
                `An error occurred: ${err.message}`,
                toast_error_settings
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!isLoading) {
            saveOrderHandler();
        }
    }, [rows]);

    const saveOrderHandler = async () => {
        setIsLoading(true);

        let theNewOrderOfList: Array<object> = [];
        rows?.map((row, index) => {
            theNewOrderOfList.push({
                id: row?.id,
                name: row?.name,
                order: index + 1,
            });
        });

        // return;
        try {
            setIsLoading(true); // Set loading state before request

            const res = await mobileAxios.post(
                "categories/update-order",
                {
                    adminId: userState?.admin_id_mobile.toString(),
                    categories: theNewOrderOfList,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );

            // After successful response
            setIsLoading(false);
            toast.success("The New Order Has Been Saved");
            fetchData();
        } catch (error: any) {
            // Handle error
            setIsLoading(false);
            toast.error(
                `An error occurred: ${
                    error.response?.data?.message || error.message
                }`, // Better error message handling
                toast_error_settings
            );
        }
    };

    useEffect(() => {
        fetchData();
    }, [userState]);

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const formData = Object.values(e.target).reduce((obj, field) => {
            if (field.type === "checkbox") {
                obj[field.name] = field.checked === true ? true : null;
            } else {
                obj[field.name] = field.value;
            }
            return obj;
        }, {});

        // console.log(formData);

        // return;
        setIsLoading(true);

        // console.log("editItemModal?.details?._id", editItemModal?.details?._id);

        try {
            // update category details
            const categoryRes = await mobileAxios(
                `categories/${editItemModal?.details?._id}`,
                {
                    method: "PATCH",
                    data: {
                        adminId: userState?.admin_id_mobile,
                        type: formData.type,
                        // order: formData.order,
                        icon: uploadedImg, 
                        name: formData.name,
                        color: formData.color,
                        // in_stock: formData.in_stock,
                        // price: formData.price,
                        // recommended: formData.recommended,
                        // contain_milk: formData.with_milk,
                    },

                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );

            seteditItemModal(null);
            setIsLoading(false);

            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    const handleAddSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // const formData = Object.values(e.target).reduce((obj, field) => {
        //     if (field.type === "checkbox") {
        //         obj[field.name] = field.checked === true ? true : null;
        //     } else {
        //         obj[field.name] = field.value;
        //     }
        //     return obj;
        // }, {});

        // console.log(formData);

        // return;
        let postedData = {
            adminId: userState?.admin_id_mobile,
            name: addName,
            color: addColor,
            icon: uploadedAddImg,
            type: addType?.value,
            country: countryId?.toString(),
        };
        console.log(postedData, "postedData");

        setIsLoading(true);

        // console.log("editItemModal?.details?._id", editItemModal?.details?._id);
        // return;
        try {
            // update category details
            const categoryRes = await mobileAxios(`/categories`, {
                method: "POST",
                data: postedData,

                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });

            setaddonsModal(null);
            setIsLoading(false);

            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };
    const toggleAddonModal = () => {
        // setSelectedTypes([]);
        // setEditValues({ title: "", price: "", type: "" });
        if (addonsModal === null) {
            setaddonsModal("open");
        } else {
            setaddonsModal(null);
        }
    };

    const openProductDetails = async (itemId: string) => {
        setIsLoading(true);

        await mobileAxios
            .get(`categories/${itemId}`)
            .then((res) => {
                setUploadedImg(res?.data?.icon);
                seteditItemModal({
                    details: res?.data,
                });
                console.log(res?.data, "data");
                console.log(res?.data?.icon, "image");

                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
                toast.error(
                    `An error has occured! ${err.message}`,
                    toast_error_settings
                );
            });

        setIsLoading(false);
    };

    const handleDragEnd = async (result: any) => {
        if (!result.destination) return;

        const updatedRows = [...rows];
        const [reorderedItem] = updatedRows.splice(result.source.index, 1);
        updatedRows.splice(result.destination.index, 0, reorderedItem);

        setRows(updatedRows);
    };

    if (isLoading) return <Loader withBg={true} />;

    return (
        <>
            <div className="h-fit mb-8 flex gap-8 items-center">
                <h2 className="font-bold text-2xl">Categories :</h2>

                <button
                    onClick={toggleAddonModal}
                    className="px-8 py-3 flex items-center justify-center border"
                >
                    + Add Category
                </button>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="table">
                    {(provided) => (
                        <TableContainer component={Paper}>
                            <Table
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Color</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Created Date</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows?.map((row, index) => (
                                        <Draggable
                                            key={row.id}
                                            draggableId={row.id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <TableRow
                                                    hover
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <TableCell>
                                                        <div className="flex gap-3 items-center">
                                                            <img
                                                                src={
                                                                    MEDIA_URL +
                                                                    row.icon
                                                                }
                                                                alt=""
                                                                className="max-w-full h-16 w-16 object-contain bg-gray-100"
                                                            />
                                                            {row.name}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            background:
                                                                row.color,
                                                        }}
                                                    >
                                                        {row.color}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.type}
                                                    </TableCell>
                                                    <TableCell>
                                                        {dayjs(
                                                            row.created_date
                                                        ).format(
                                                            "ddd DD MMM YYYY hh:mm A"
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <MUIButton
                                                            variant="outlined"
                                                            onClick={() => {
                                                                // Handle edit click
                                                                openProductDetails(
                                                                    row.id
                                                                );
                                                            }}
                                                        >
                                                            Edit
                                                        </MUIButton>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Droppable>
            </DragDropContext>

            {/* Modal for Edit */}
            <Modal
                size="5xl"
                isOpen={editItemModal !== null}
                toggleModal={() => seteditItemModal(null)}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedImg}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="name"
                            type="text"
                            placeholder="Name *"
                            defaultValue={editItemModal?.details?.name}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="color"
                            type="text"
                            placeholder="Color *"
                            defaultValue={editItemModal?.details?.color}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <select
                            name="type"
                            id="type"
                            defaultValue={editItemModal?.details?.type}
                        >
                            {types.map((type: any) => (
                                <option
                                    // key={category._id}
                                    value={type.value}
                                    selected={
                                        type?.value ===
                                        editItemModal?.details?.category
                                    }
                                >
                                    {type.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => seteditItemModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            {/* Modal for add */}
            <Modal
                size="5xl"
                isOpen={addonsModal !== null}
                toggleModal={() => toggleAddonModal}
            >
                <form className="mt-6" onSubmit={handleAddSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedAddImg}
                            handleIconUpload={(src: string) =>
                                setUploadedAddImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="name"
                            type="text"
                            placeholder="Name *"
                            // defaultValue={editItemModal?.details?.name}
                            onChange={(e) => setAddName(e?.target?.value)}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="color"
                            type="text"
                            placeholder="Color *"
                            onChange={(e) => setAddColor(e?.target?.value)}
                            // defaultValue={editItemModal?.details?.color}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <Select
                            value={addType}
                            placeholder={"Type *"}
                            onChange={(e) => {
                                setAddType(e as any);
                            }}
                            name="type"
                            options={types.map((item) => ({
                                value: item?.value,
                                label: item?.label,
                            }))}
                            required
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => {
                                setUploadedAddImg("");
                                setAddType("");
                                toggleAddonModal();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default CategoriesByCountry;
