import React, { useState } from "react";

import { useUser } from "../../context/UserContext";
import { Axios } from "../../utils/axios";

import Dropzone from "../Dropzone";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { MEDIA_URL, toast_error_settings } from "../../utils/constants";
import { toast } from "react-toastify";

type Props = {
    src: string;
    component: any;
    fetchData: () => void;
    setLoading?: () => void;
};

const SliderImageEdit = ({ src, component, fetchData, setLoading }: Props) => {
    const { userState, setUser } = useUser();

    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [uploadedImg, setUploadedImg] = useState<string>(src);

    const toggleEditModal = () => {
        setEditModalOpen((p) => !p);
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen((p) => !p);
    };

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading && setLoading();
        try {
            const res = await Axios.post("update_slider_image", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                slider_id: component.image_id,
                slider_image: uploadedImg,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        toggleEditModal();
    };

    const handleDeleteSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading && setLoading();
        try {
            const res = await Axios.post("delete_slider_image", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                slider_id: component.image_id,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        toggleDeleteModal();
    };

    return (
        <>
            <Modal
                size="2xl"
                isOpen={editModalOpen}
                toggleModal={toggleEditModal}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={src}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleEditModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal isOpen={deleteModalOpen} toggleModal={toggleDeleteModal}>
                <form className="mt-6" onSubmit={handleDeleteSubmit}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this image?
                    </h4>
                    <div className="flex space-x-4">
                        <Button
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-auto mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>

            <div className="text-left flex group gap-1">
                <div
                    className="flex-1 cursor-pointer"
                    onClick={() => toggleEditModal()}
                >
                    <img
                        src={MEDIA_URL + src}
                        alt=""
                        className={`min-h-[20px] min-w-[20px]`}
                    />
                </div>
                <div className="flex flex-col">
                    <button
                        onClick={() => toggleEditModal()}
                        className="text-xl translate-x-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:translate-x-0 transition-all"
                    >
                        <AiFillEdit />
                    </button>

                    <button
                        onClick={() => toggleDeleteModal()}
                        className="mt-2 text-red-600 text-xl translate-x-1/2 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:translate-x-0 transition-all"
                    >
                        <AiFillDelete />
                    </button>
                </div>
            </div>
        </>
    );
};

export default SliderImageEdit;
