import React, { useState } from "react";
import { toast } from "react-toastify";

import { useUser } from "../../context/UserContext";

import { Axios } from "../../utils/axios";
import { toast_error_settings } from "../../utils/constants";
import EditWrapper from "../EditWrapper";

import Button from "../UI/Button";
import Modal from "../UI/Modal";
import TitleWithText from "./TitleWithText";

type Props = {
    component_id: string | number;
    title: string;
    description: string;
    fetchData: () => void;
    handleSubmit: (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => void;
    type: string;
    setLoading: () => void;
};

const FaqRow = ({
    component_id,
    handleSubmit,
    description,
    title,
    type,
    fetchData,
    setLoading,
}: Props) => {
    const { userState, setUser } = useUser();
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

    const toggleEditModal = () => {
        setEditModalOpen((p) => !p);
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen((p) => !p);
    };

    const handleDeleteSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading();
        try {
            const res = await Axios.post("delete_faq", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                faq_id: component_id,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        toggleDeleteModal();
    };

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});

        try {
            const res = await Axios.post("update_faq", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                faq_id: component_id,
                title: data.title,
                description: data.description,
                type: data.type,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
        toggleEditModal();
    };

    return (
        <>
            <Modal isOpen={deleteModalOpen} toggleModal={toggleDeleteModal}>
                <form className="mt-6" onSubmit={handleDeleteSubmit}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete it?
                    </h4>
                    <div className="flex space-x-4">
                        <Button
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-auto mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                size="3xl"
                isOpen={editModalOpen}
                toggleModal={toggleEditModal}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    {type && (
                        <div className="input-row">
                            <input
                                name="type"
                                type="text"
                                placeholder="Type *"
                                defaultValue={type}
                                required
                            />
                        </div>
                    )}

                    {title && (
                        <div className="input-row">
                            <input
                                name="title"
                                type="text"
                                placeholder="Title *"
                                defaultValue={title}
                                required
                            />
                        </div>
                    )}

                    {description && (
                        <div className="input-row">
                            <textarea
                                name="description"
                                placeholder="Description *"
                                defaultValue={description}
                                rows={4}
                                required
                            />
                        </div>
                    )}

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleEditModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <EditWrapper
                ref={undefined}
                onDelete={() => toggleDeleteModal()}
                toggleModal={toggleEditModal}
            >
                <h2
                    className="font-bold text-xl mb-2"
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                />
                <p
                    className="whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </EditWrapper>
        </>
    );
};

export default FaqRow;
