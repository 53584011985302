import React, { useState } from "react";
import { MEDIA_URL } from "../../utils/constants";

import Dropzone from "../Dropzone";

import EditWrapper from "../EditWrapper";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

type Props = {
    src: string;
    component_id: string | number;
    sectionKey: string;
    className?: string;
    onSubmit: (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => void;
};

const ImageEdit = ({
    component_id,
    src,
    className,
    onSubmit,
    sectionKey,
}: Props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [uploadedImg, setUploadedImg] = useState<string>(src);

    const toggleModal = () => {
        setModalOpen((p) => !p);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        onSubmit(
            {
                component_title: uploadedImg,
            },
            sectionKey,
            component_id
        );
        toggleModal();
    };

    return (
        <>
            <Modal size="2xl" isOpen={modalOpen} toggleModal={toggleModal}>
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={src}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            <EditWrapper ref={undefined} toggleModal={toggleModal}>
                <img
                    src={MEDIA_URL + src}
                    alt=""
                    className={`min-h-[20px] min-w-[20px] ${className}`}
                />
            </EditWrapper>
        </>
    );
};

export default ImageEdit;
