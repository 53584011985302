export const findBySlug = (
    array: any[],
    slug: string,
    multiple: boolean = false
): any | undefined => {
    if (multiple) {
        return array.filter((item: any) => item.slug === slug);
    }
    return array.find((item: any) => item.slug === slug);
};
