import React, { useState } from "react";
import { MEDIA_URL } from "../../utils/constants";

import Dropzone from "../Dropzone";
import EditWrapper from "../EditWrapper";
import Button from "../UI/Button";
import Modal from "../UI/Modal";

type Props = {
    merch_id: number | string;
    image: string;
    title: string;
    description: string;
    price: string | number;
    handleUpdate: (
        merch_id: string | number,
        title?: string,
        description?: string,
        price?: string | number,
        image?: string,
        category?: string
    ) => void;
    category: string;
};

const MerchCard = ({
    description,
    image,
    merch_id,
    price,
    title,
    handleUpdate,
    category,
}: Props) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [uploadedImg, setUploadedImg] = useState<string>(image);

    const toggleEditModal = () => {
        setEditModalOpen((p) => !p);
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen((p) => !p);
    };

    const handleDeleteSubmit = () => {
        handleUpdate(merch_id);
        toggleDeleteModal();
    };

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = Object.values(e.target).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});
        console.log(data.category);

        handleUpdate(
            merch_id,
            data.title,
            data.description,
            data.price,
            uploadedImg,
            data.category
        );
        toggleEditModal();
    };

    return (
        <>
            <Modal isOpen={deleteModalOpen} toggleModal={toggleDeleteModal}>
                <form className="mt-6" onSubmit={handleDeleteSubmit}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete it?
                    </h4>
                    <div className="flex space-x-4">
                        <Button
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="ml-auto mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                size="3xl"
                isOpen={editModalOpen}
                toggleModal={toggleEditModal}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={image}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    {category && (
                        <div className="input-row">
                            <input
                                name="category"
                                type="hidden"
                                placeholder="Category *"
                                defaultValue={category}
                                disabled
                                required
                            />
                        </div>
                    )}

                    {category && (
                        <div className="input-row px-2">{category}</div>
                    )}

                    {title && (
                        <div className="input-row">
                            <input
                                name="title"
                                type="text"
                                placeholder="Title *"
                                defaultValue={title}
                                required
                            />
                        </div>
                    )}

                    {price && (
                        <div className="input-row flex items-center gap-2">
                            <span className="text-xl">$</span>
                            <input
                                className="flex-1"
                                name="price"
                                type="number"
                                step="any"
                                placeholder="Price *"
                                defaultValue={price}
                                required
                            />
                        </div>
                    )}

                    {description && (
                        <div className="input-row">
                            <textarea
                                name="description"
                                placeholder="Description *"
                                defaultValue={description}
                                rows={4}
                                required
                            />
                        </div>
                    )}

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => toggleEditModal()}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            <EditWrapper
                ref={undefined}
                toggleModal={toggleEditModal}
                onDelete={toggleDeleteModal}
            >
                <div className="">
                    <img
                        src={MEDIA_URL + image}
                        alt=""
                        className="object-cover max-w-full"
                    />
                    <div className="flex justify-between my-2">
                        <span className="font-bold text-lg">{title}</span>
                        <span>${price}</span>
                    </div>
                </div>
            </EditWrapper>
        </>
    );
};

export default MerchCard;
