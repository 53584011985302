import { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";

import { findBySlug } from "../../utils/functions";

import { Axios } from "../../utils/axios";
import TitleWithText from "../../Components/EditComponents/TitleWithText";
import Loader from "../../Components/Loader/Loader";
import ImageEdit from "../../Components/EditComponents/Image";
import ButtonEdit from "../../Components/EditComponents/ButtonEdit";
import ServiceEdit from "../../Components/ServiceEdit";
import AddService from "../../Components/AddService";
import { toast } from "react-toastify";
import { toast_error_settings } from "../../utils/constants";

type Props = {};

const AdminServices = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { userState, setUser } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        Axios.post("build_pages", {
            adminid: userState?.admin_id,
            token: userState?.admin_login_token,
            page_id: 5,
        })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => {
        setIsLoading(true);
        const updated_components = data[sectionKey].map((item: any) => {
            if (
                item.component_id.toString() ===
                updating_component_id.toString()
            ) {
                return {
                    ...item,
                    component_title: submittedData.component_title || "",
                    component_description:
                        submittedData.component_description || "",
                };
            } else {
                return {
                    ...item,
                    component_title: item.title || "",
                    component_description: item.description || "",
                };
            }
        });

        try {
            const res = await Axios.post("update_component", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                components: updated_components,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    return (
        <div>
            {isLoading && <Loader withBg={true} />}

            <div className="w-full flex">
                <div className="flex-1">
                    <TitleWithText
                        component_id={
                            findBySlug(data["hero"], "text").component_id
                        }
                        title={findBySlug(data["hero"], "text").title}
                        text={findBySlug(data["hero"], "text").description}
                        onSubmit={handleSubmit}
                        sectionKey="hero"
                    />
                </div>
                <div className="flex-[0.4]">
                    <ImageEdit
                        component_id={
                            findBySlug(data["hero"], "image").component_id
                        }
                        sectionKey="hero"
                        src={findBySlug(data["hero"], "image").title}
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>

            <hr className="my-10" />

            {/* services */}
            <div>
                {/* <TitleWithText
                    component_id={
                        findBySlug(data["services"], "title").component_id
                    }
                    title={findBySlug(data["services"], "title").title}
                    text={""}
                    onSubmit={handleSubmit}
                    sectionKey="services"
                /> */}
                <div className="grid grid-cols-2 gap-3">
                    {data["services"]?.map((service: any) => (
                        <div key={service.component_id}>
                            <ServiceEdit
                                setIsLoading={setIsLoading}
                                key={service.component_id}
                                component_id={service.story_id}
                                title={service.title}
                                text={service.description}
                                sectionKey="services"
                                fetchData={fetchData}
                                serviceItem={service}
                            />
                        </div>
                    ))}
                </div>
                <br />

                <AddService
                    fetchData={fetchData}
                    setLoading={() => setIsLoading(true)}
                />
            </div>

            <hr className="my-10" />

            {/* call to action */}
            <div>
                <TitleWithText
                    component_id={
                        findBySlug(data["call-to-action-services"], "text")
                            .component_id
                    }
                    title={""}
                    text={
                        findBySlug(data["call-to-action-services"], "text")
                            .description
                    }
                    onSubmit={handleSubmit}
                    sectionKey="call-to-action-services"
                />

                {/* <div className="w-fit ml-auto mt-2">
                    <ButtonEdit
                        component_id={
                            findBySlug(data["call-to-action"], "button")
                                .component_id
                        }
                        button={{
                            text: findBySlug(data["call-to-action"], "button")
                                .title,
                            href: findBySlug(data["call-to-action"], "button")
                                .description,
                        }}
                        onSubmit={handleSubmit}
                        sectionKey="call-to-action"
                    />
                </div> */}
            </div>
        </div>
    );
};

export default AdminServices;
