import { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";

import { findBySlug } from "../../utils/functions";

import { Axios } from "../../utils/axios";
import IconWithText from "../../Components/EditComponents/IconWithText";
import ImageEdit from "../../Components/EditComponents/Image";
import TitleWithText from "../../Components/EditComponents/TitleWithText";
import ButtonEdit from "../../Components/EditComponents/ButtonEdit";
import SliderImageEdit from "../../Components/EditComponents/SliderImageEdit";
import AddImageToSlider from "../../Components/AddImageToSlider";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { toast_error_settings } from "../../utils/constants";

type Props = {};

const AdminHome = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { userState } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        Axios.post("build_pages", {
            adminid: userState?.admin_id,
            token: userState?.admin_login_token,
            page_id: 1,
        })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => {
        setIsLoading(true);
        const updated_components = data[sectionKey].map((item: any) => {
            if (
                item.component_id.toString() ===
                updating_component_id.toString()
            ) {
                return {
                    ...item,
                    component_title: submittedData.component_title,
                    component_description:
                        submittedData.component_description || "",
                };
            } else {
                return {
                    ...item,
                    component_title: item.title,
                    component_description: item.description || "",
                };
            }
        });

        try {
            const res = await Axios.post("update_component", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                components: updated_components,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    return (
        <div>
            {isLoading && <Loader withBg={true} />}
            {/* hero */}
            <div>
                <TitleWithText
                    component_id={
                        findBySlug(data["intro"], "text").component_id
                    }
                    title={findBySlug(data["intro"], "text").title}
                    text={findBySlug(data["intro"], "text").description}
                    onSubmit={handleSubmit}
                    sectionKey="intro"
                />

                <div className="mt-2">
                    <ButtonEdit
                        component_id={
                            findBySlug(data["intro"], "button").component_id
                        }
                        button={{
                            text: findBySlug(data["intro"], "button").title,
                            href: findBySlug(data["intro"], "button")
                                .description,
                        }}
                        onSubmit={handleSubmit}
                        sectionKey="intro"
                    />
                </div>
            </div>

            {/* hero sliders */}
            <div className="mt-4 grid grid-cols-3 gap-4">
                {data["slider"].map(
                    (item: any) =>
                        item.active === 1 && (
                            <div key={item.component_id}>
                                <SliderImageEdit
                                    component={item}
                                    src={item.image_src}
                                    fetchData={fetchData}
                                    setLoading={() => setIsLoading(true)}
                                />
                            </div>
                        )
                )}
            </div>
            <br />
            <AddImageToSlider
                slider_page="home"
                slider_section="intro"
                fetchData={fetchData}
                setLoading={() => setIsLoading(true)}
            />

            <hr className="my-10" />

            {/* Icons */}
            <div className="flex items-center justify-between w-full">
                {data["icons"].map((icon: any) => (
                    <IconWithText
                        key={icon.component_id}
                        component_id={icon.component_id}
                        icon={icon.description}
                        text={icon.title}
                        onSubmit={handleSubmit}
                        sectionKey="icons"
                    />
                ))}
            </div>

            <hr className="my-10" />

            {/* story */}
            {/* story banner */}
            <div className="w-full">
                <ImageEdit
                    component_id={
                        findBySlug(data["story"], "image-banner").component_id
                    }
                    src={findBySlug(data["story"], "image-banner").title}
                    className="object-contain"
                    onSubmit={handleSubmit}
                    sectionKey="story"
                />
            </div>

            {/* story text */}
            <div className="w-full mt-4 whitespace-pre-wrap">
                <TitleWithText
                    component_id={
                        findBySlug(data["story"], "text").component_id
                    }
                    title={findBySlug(data["story"], "text").title}
                    text={findBySlug(data["story"], "text").description}
                    onSubmit={handleSubmit}
                    sectionKey="story"
                />
            </div>

            <hr className="my-10" />

            {/* workspace */}
            <div className="flex gap-4">
                {/* images */}
                <div className="flex-[0.4]">
                    <figure>
                        <figcaption className="whitespace-nowrap text-xs">
                            image back
                        </figcaption>

                        <ImageEdit
                            component_id={
                                findBySlug(data["workspace"], "image_back")
                                    .component_id
                            }
                            sectionKey="workspace"
                            src={
                                findBySlug(data["workspace"], "image_back")
                                    .title
                            }
                            className="object-contain"
                            onSubmit={handleSubmit}
                        />
                    </figure>

                    <figure className="mt-2">
                        <figcaption className="whitespace-nowrap text-xs">
                            image front
                        </figcaption>
                        <ImageEdit
                            component_id={
                                findBySlug(data["workspace"], "image_front")
                                    .component_id
                            }
                            sectionKey="workspace"
                            src={
                                findBySlug(data["workspace"], "image_front")
                                    .title
                            }
                            className="object-contain"
                            onSubmit={handleSubmit}
                        />
                    </figure>
                </div>

                {/* text */}
                <div className="flex-1">
                    <TitleWithText
                        component_id={
                            findBySlug(data["workspace"], "text").component_id
                        }
                        title={findBySlug(data["workspace"], "text").title}
                        text={findBySlug(data["workspace"], "text").description}
                        onSubmit={handleSubmit}
                        sectionKey="workspace"
                    />
                </div>
            </div>

            <hr className="my-10" />

            {/* community */}
            <div className="flex gap-4">
                <div className="flex-1">
                    <TitleWithText
                        component_id={
                            findBySlug(data["community"], "text").component_id
                        }
                        title={findBySlug(data["community"], "text").title}
                        text={findBySlug(data["community"], "text").description}
                        onSubmit={handleSubmit}
                        sectionKey="community"
                    />
                    <ButtonEdit
                        component_id={
                            findBySlug(data["community"], "button").component_id
                        }
                        button={{
                            text: findBySlug(data["community"], "button").title,
                            href: findBySlug(data["community"], "button")
                                .description,
                        }}
                        onSubmit={handleSubmit}
                        sectionKey="community"
                    />
                </div>
                <div className="flex-[0.4]">
                    <ImageEdit
                        component_id={
                            findBySlug(data["community"], "image").component_id
                        }
                        sectionKey="community"
                        src={findBySlug(data["community"], "image").title}
                        className="object-contain"
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>
            {/* event */}
            <div className="flex gap-4 mt-4">
                <div className="flex-1">
                    <TitleWithText
                        component_id={
                            findBySlug(data["event"], "text").component_id
                        }
                        title={findBySlug(data["event"], "text").title}
                        text={findBySlug(data["event"], "text").description}
                        onSubmit={handleSubmit}
                        sectionKey="event"
                    />
                    <ButtonEdit
                        component_id={
                            findBySlug(data["event"], "button").component_id
                        }
                        button={{
                            text: findBySlug(data["event"], "button").title,
                            href: findBySlug(data["event"], "button")
                                .description,
                        }}
                        onSubmit={handleSubmit}
                        sectionKey="event"
                    />
                </div>
                <div className="flex-[0.4]">
                    <ImageEdit
                        component_id={
                            findBySlug(data["event"], "image").component_id
                        }
                        sectionKey="event"
                        src={findBySlug(data["event"], "image").title}
                        className="object-contain"
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>

            <hr className="my-10" />

            {/* call to action */}
            {/* <div>
                <TitleWithText
                    component_id={
                        findBySlug(data["call-to-action"], "text").component_id
                    }
                    title={""}
                    text={
                        findBySlug(data["call-to-action"], "text").description
                    }
                    onSubmit={handleSubmit}
                    sectionKey="call-to-action"
                />

                <div className="w-fit ml-auto mt-2">
                    <ButtonEdit
                        component_id={
                            findBySlug(data["call-to-action"], "button")
                                .component_id
                        }
                        button={{
                            text: findBySlug(data["call-to-action"], "button")
                                .title,
                            href: findBySlug(data["call-to-action"], "button")
                                .description,
                        }}
                        onSubmit={handleSubmit}
                        sectionKey="call-to-action"
                    />
                </div>
            </div> */}
        </div>
    );
};

export default AdminHome;
