import { useState, useEffect } from "react";
import { useUser } from "../../context/UserContext";

import { Axios } from "../../utils/axios";
import { findBySlug } from "../../utils/functions";

import ImageEdit from "../../Components/EditComponents/Image";
import TitleWithText from "../../Components/EditComponents/TitleWithText";
import Loader from "../../Components/Loader/Loader";
import EditWorkspaceRoom from "../../Components/EditComponents/EditWorkspaceRoom";
import ZoneCaracteristic from "../../Components/EditComponents/ZoneCaracteristic";
import { toast } from "react-toastify";
import { toast_error_settings } from "../../utils/constants";

type Props = {};

const AdminSpaces = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { userState } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        Axios.post("build_pages", {
            adminid: userState?.admin_id,
            token: userState?.admin_login_token,
            page_id: 2,
        })
            .then((res) => {
                if (res.data.length === 0) {
                    throw new Error("Nothing returned");
                }
                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    const Bzones: {
        id: string | number;
        name: string;
        image: string;
        body: {
            caracteristic_name: string;
            caracteristic_description: string;
        }[];
    }[] = [];
    const Czones: {
        id: string | number;
        name: string;
        image: string;
        body: {
            caracteristic_name: string;
            caracteristic_description: string;
        }[];
    }[] = [];

    const foundRooms: number[] = [];
    if (data && data["rooms-B"]) {
        data["rooms-B"].forEach((room: any) => {
            if (foundRooms.includes(room.room_id)) {
                Bzones.find((zone) => zone.name === room.room_name)?.body.push({
                    caracteristic_name: room.caracteristic,
                    caracteristic_description: room.description,
                });
            } else {
                Bzones.push({
                    id: room.room_id,
                    name: room.room_name,
                    image: room.image,
                    body: [
                        {
                            caracteristic_name: room.caracteristic,
                            caracteristic_description: room.description,
                        },
                    ],
                });
                foundRooms.push(room.room_id);
            }
        });
    }
    if (data && data["rooms-C"]) {
        data["rooms-C"].forEach((room: any) => {
            if (foundRooms.includes(room.room_id)) {
                Czones.find((zone) => zone.name === room.room_name)?.body.push({
                    caracteristic_name: room.caracteristic,
                    caracteristic_description: room.description,
                });
            } else {
                Czones.push({
                    id: room.room_id,
                    name: room.room_name,
                    image: room.image,
                    body: [
                        {
                            caracteristic_name: room.caracteristic,
                            caracteristic_description: room.description,
                        },
                    ],
                });
                foundRooms.push(room.room_id);
            }
        });
    }

    if (data === "loading") {
        return <Loader />;
    }

    const handleSubmit = async (
        submittedData: {
            component_title?: string;
            component_description?: string;
        },
        sectionKey: string,
        updating_component_id: string | number
    ) => {
        setIsLoading(true);
        const updated_components = data[sectionKey].map((item: any) => {
            if (
                item.component_id.toString() ===
                updating_component_id.toString()
            ) {
                return {
                    ...item,
                    component_title: submittedData.component_title,
                    component_description:
                        submittedData.component_description || "",
                };
            } else {
                return {
                    ...item,
                    component_title: item.title,
                    component_description: item.description || "",
                };
            }
        });

        try {
            const res = await Axios.post("update_component", {
                adminid: userState?.admin_id,
                token: userState?.admin_login_token,
                components: updated_components,
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }
    };

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            {/* hero */}
            <div className="w-full">
                {/* <div className="flex-1">
                    <TitleWithText
                        component_id={
                            findBySlug(data["hero"], "text").component_id
                        }
                        title={findBySlug(data["hero"], "text").title}
                        text={findBySlug(data["hero"], "text").description}
                        onSubmit={handleSubmit}
                        sectionKey="hero"
                    />
                </div> */}
                <ImageEdit
                    component_id={
                        findBySlug(data["hero"], "image").component_id
                    }
                    sectionKey="hero"
                    src={findBySlug(data["hero"], "image").title}
                    onSubmit={handleSubmit}
                />
            </div>

            <hr className="my-10" />

            <div className="flex gap-2">
                <div className="flex-1">
                    {/* <TitleWithText
                        component_id={
                            findBySlug(data["intro"], "space").component_id
                        }
                        title={findBySlug(data["intro"], "space").title}
                        text={findBySlug(data["intro"], "space").description}
                        onSubmit={handleSubmit}
                        sectionKey="intro"
                    /> */}
                    <div className="mt-4">
                        <TitleWithText
                            component_id={
                                findBySlug(data["intro"], "workspace")
                                    .component_id
                            }
                            title={findBySlug(data["intro"], "workspace").title}
                            text={
                                findBySlug(data["intro"], "workspace")
                                    .description
                            }
                            onSubmit={handleSubmit}
                            sectionKey="intro"
                        />
                    </div>
                </div>
                <div className="flex-[0.4]">
                    <ImageEdit
                        component_id={
                            findBySlug(data["intro"], "image").component_id
                        }
                        src={findBySlug(data["intro"], "image").title}
                        sectionKey="intro"
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>

            <hr className="my-10" />

            {/* beirut zone */}
            <div>
                <TitleWithText
                    component_id={
                        findBySlug(data["workspace-B"], "workspace")
                            .component_id
                    }
                    title={findBySlug(data["workspace-B"], "workspace").title}
                    text={
                        findBySlug(data["workspace-B"], "workspace").description
                    }
                    onSubmit={handleSubmit}
                    sectionKey="intro"
                />

                <div className="mt-4">
                    {Bzones &&
                        Bzones.map((zone, index) => (
                            <div
                                key={zone.id + index.toString()}
                                className="flex mb-6 gap-4"
                            >
                                <div className="flex-1">
                                    <EditWorkspaceRoom
                                        image={zone.image}
                                        room_id={zone.id}
                                        room_name={zone.name}
                                        fetchData={fetchData}
                                        setLoading={() => setIsLoading(true)}
                                    />
                                </div>
                                <div className="flex-1 mt-8">
                                    {zone.body.map((carac, caraIndex) => (
                                        <div
                                            key={
                                                carac.caracteristic_name +
                                                caraIndex.toString()
                                            }
                                            className="mb-2"
                                        >
                                            <ZoneCaracteristic
                                                title={carac.caracteristic_name}
                                                text={
                                                    carac.caracteristic_description
                                                }
                                                room_id={zone.id}
                                                // caracteristic_id={carac.id}
                                                fetchData={fetchData}
                                                setLoading={() =>
                                                    setIsLoading(true)
                                                }
                                            />
                                        </div>
                                    ))}

                                    <ZoneCaracteristic
                                        add={true}
                                        fetchData={fetchData}
                                        setLoading={() => setIsLoading(true)}
                                        room_id={zone.id}
                                    />
                                </div>
                            </div>
                        ))}

                    <EditWorkspaceRoom
                        add={true}
                        fetchData={fetchData}
                        setLoading={() => setIsLoading(true)}
                        workspace="Beirut"
                    />
                </div>
            </div>

            <br />
            <br />

            <div>
                <TitleWithText
                    component_id={
                        findBySlug(data["workspace-C"], "workspace")
                            .component_id
                    }
                    title={findBySlug(data["workspace-C"], "workspace").title}
                    text={
                        findBySlug(data["workspace-C"], "workspace").description
                    }
                    onSubmit={handleSubmit}
                    sectionKey="intro"
                />

                <div className="mt-4">
                    {Czones &&
                        Czones.map((zone, index) => (
                            <div
                                key={zone.id + index.toString()}
                                className="flex mb-6 gap-4"
                            >
                                <div className="flex-1">
                                    <EditWorkspaceRoom
                                        image={zone.image}
                                        room_id={zone.id}
                                        room_name={zone.name}
                                        fetchData={fetchData}
                                        setLoading={() => setIsLoading(true)}
                                    />
                                </div>
                                <div className="flex-1 mt-8">
                                    {zone.body.map((carac, caraIndex) => (
                                        <div
                                            key={
                                                carac.caracteristic_name +
                                                caraIndex.toString()
                                            }
                                            className="mb-2"
                                        >
                                            <ZoneCaracteristic
                                                title={carac.caracteristic_name}
                                                text={
                                                    carac.caracteristic_description
                                                }
                                                room_id={zone.id}
                                                // caracteristic_id={carac.id}
                                                fetchData={fetchData}
                                                setLoading={() =>
                                                    setIsLoading(true)
                                                }
                                            />
                                        </div>
                                    ))}

                                    <ZoneCaracteristic
                                        add={true}
                                        fetchData={fetchData}
                                        setLoading={() => setIsLoading(true)}
                                        room_id={zone.id}
                                    />
                                </div>
                            </div>
                        ))}

                    <EditWorkspaceRoom
                        add={true}
                        fetchData={fetchData}
                        setLoading={() => setIsLoading(true)}
                        workspace="Canada"
                    />
                </div>
            </div>

            <hr className="my-10" />
        </>
    );
};

export default AdminSpaces;
