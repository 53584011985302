import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useUser } from "../../context/UserContext";
import { mobileAxios } from "../../utils/mobileAxios";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";

type Props = {};

function descendingComparator(a: any, b: any, orderBy: string) {
    if (orderBy === "user") {
        return b[orderBy]?.name.localeCompare(a[orderBy]?.name);
    } else if (orderBy === "id" || orderBy === "status") {
        return b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy.includes("date") || orderBy.includes("from") || orderBy.includes("till")) {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order: any, orderBy: any) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: "user",
        label: "User",
    },
    {
        id: "room",
        label: "Room",
    },
    {
        id: "from",
        label: "From Date",
    },
    {
        id: "till",
        label: "Till Date",
    },
];

function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const Bookings = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [rows, setRows] = useState([]);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("from");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const { userState } = useUser();

    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        mobileAxios
            .get(`/spaces/book`)
            .then((res) => {
                const rowsData = res.data?.map((item: any) => ({
                    id: item._id,
                    created_date: item.created_date,
                    user: {
                        name: item.user_id?.name,
                        email: item.user_id?.email,
                    },
                    room: {
                        name: item.space_id?.title,
                        id: item.space_id?.branch,
                    },
                    from: item.from_date,
                    till: item.till_date,
                }));

                setRows(rowsData);

                setData(res.data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    if (data === "loading") {
        return <Loader />;
    }

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            {data?.length === 0 ? (
                <p className="text-center mt-6 text-2xl font-bold">
                    No booking availble for this room
                </p>
            ) : (
                rows.length > 0 && (
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        onSelectAllClick={() => { }}
                                    />
                                    <TableBody>
                                        {rows
                                            .sort(getComparator(order, orderBy))
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row: any, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        className="!text-base"
                                                    >
                                                        <TableCell>
                                                            <div className="">
                                                                <b>Name: </b>{" "}
                                                                {row.user?.name}
                                                                <br />
                                                                <b>
                                                                    Email:{" "}
                                                                </b>{" "}
                                                                <a
                                                                    href={`mailto:${row.user?.email}`}
                                                                >
                                                                    {
                                                                        row.user
                                                                            ?.email
                                                                    }
                                                                </a>
                                                            </div>
                                                        </TableCell>

                                                        <TableCell>
                                                            <NavLink
                                                                to={`/mobile-app/spaces/${row.room?.id}`}
                                                                className="text-lightgreen"
                                                            >
                                                                {row.room?.name}
                                                            </NavLink>
                                                        </TableCell>
                                                        {/* <TableCell>
                                                        {dayjs(
                                                            row.created_date
                                                        ).format(
                                                            "ddd DD MMM YYYY hh:mm A"
                                                        )}
                                                    </TableCell> */}

                                                        <TableCell>
                                                            {row.from}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.till}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                )
            )}
        </>
    );
};

export default Bookings;
