import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Dropzone from "../../../Components/Dropzone";
import Loader from "../../../Components/Loader/Loader";
import Button from "../../../Components/UI/Button";
import Modal from "../../../Components/UI/Modal";
import { useUser } from "../../../context/UserContext";
import { MEDIA_URL, toast_error_settings } from "../../../utils/constants";
import { mobileAxios } from "../../../utils/mobileAxios";

import { Button as MUIButton } from "@mui/material";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";
import { log } from "console";

type Props = {};

function descendingComparator(a: any, b: any, orderBy: any) {
    if (orderBy === "total_price") {
        if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
            return -1;
        }
        if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
            return 1;
        }
        return 0;
    } else if (orderBy === "id" || orderBy === "statys") {
        return b[orderBy].localeCompare(a[orderBy]);
    } else if (orderBy === "created_date") {
        return dayjs(b[orderBy]).isAfter(dayjs(a[orderBy])) ? -1 : 1;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order: any, orderBy: any) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

const headCells = [
    {
        id: "name",
        label: "Name",
    },
    {
        id: "price",
        label: "Price",
    },
    {
        id: "recommended",
        label: "Recommended",
    },
    {
        id: "category",
        label: "Category",
    },
    {
        id: "status",
        label: "Status",
    },
    {
        id: "action",
        label: "",
    },
];

function EnhancedTableHead(props: any) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            className="font-semibold text-base"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const AdminMenuByCountry = (props: Props) => {
    const [data, setData] = useState<any>("loading");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [uploadedImg, setUploadedImg] = useState<string | undefined>(
        undefined
    );

    const [allCategories, setAllCategories] = useState([]);

    const [editItemModal, seteditItemModal] = useState<{
        details: any;
        variations: any[];
    } | null>(null);

    const [newVariationOpen, setNewVariationOpen] = useState(false);

    const [addItemModal, setAddItemModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<string | null>(null);

    const toggleAddItemModal = () => {
        setAddItemModal((p) => !p);
        setNewVariationOpen(false);
        setUploadedImg(undefined);
    };

    const [rows, setRows] = useState([]);

    console.log("rows", rows);

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("created_date");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [inputSearchText, setInputSearchText] = useState("");
    const onSearchTextChange = (e: any) => {
        setInputSearchText(e.target.value);
    };
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const { userState } = useUser();

    const params = useParams();

    const getCategoryName = (idCategory: any) => {
        if (allCategories.length > 0) {
            console.log("allCategories", allCategories);

            let x = allCategories?.filter((c) => c["_id"] === idCategory)[0][
                "name"
            ];
            console.log("x", x);

            return x;
        }
    };

    //HERE I AM GETTING THE DATA
    const fetchData = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        await mobileAxios
            .get(`/admin/menu/country/${params.country_id}`)

            .then((res) => {
                const tmp = res.data.sort(
                    (a: any, b: any) =>
                        new Date(b.created_date).getTime() -
                        new Date(a.created_date).getTime()
                );

                const rowsData = res.data?.map((item: any) => ({
                    id: item._id,
                    created_date: item.created_date,
                    name: item.name,
                    image: item.image,
                    price: item.price,
                    recommended: item.recommended,
                    category: getCategoryName(item.category),
                    in_stock: item.in_stock ?? "true",
                    status: "IN STOCK",
                }));

                setRows(rowsData);

                setData(tmp);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setData(err.message);
                setIsLoading(false);
            });
    };

    const getCategory = async () => {
        setIsLoading(true);
        await mobileAxios
            .get(`categories`)
            .then((res) => {
                console.log(res?.data);

                setAllCategories(
                    res.data.filter((item: any) => item.type === "menu")
                );
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
            });
    };

    // HERE I AM GETTING THE CATEGORIES
    useEffect(() => {
        getCategory();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userState]);

    useEffect(() => {
        fetchData();
    }, [allCategories]);

    if (data === "loading") {
        return <Loader />;
    }

    const handleAddNewProduct = async (e: React.FormEvent) => {
        e.preventDefault();
        // setIsLoading(true);

        const formData = Object.values(e.target).reduce((obj, field) => {
            if (field.type === "checkbox") {
                obj[field.name] = field.checked === true ? true : null;
            } else {
                obj[field.name] = field.value;
            }
            return obj;
        }, {});

        try {
            const res = await mobileAxios("menu", {
                method: "POST",
                data: {
                    adminId: userState?.admin_id_mobile,
                    country: params.country_id,
                    category: formData.category,
                    order: formData.order,
                    image: uploadedImg,
                    name: formData.name,
                    price: formData.price,
                    in_stock: formData.in_stock,
                    recommended: formData.recommended,
                    contain_milk: formData.with_milk,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setUploadedImg(undefined);
        toggleAddItemModal();
    };

    const handleItemDelete = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await mobileAxios(`menu/${deleteModal}`, {
                method: "DELETE",
                data: {
                    adminId: userState?.admin_id_mobile,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
            if (res.data === "token error") {
                throw new Error(res.data);
            }
            if (!res.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
        setDeleteModal(null);
    };

    const openProductDetails = async (itemId: string) => {
        setIsLoading(true);

        await mobileAxios
            .get(`menu/${itemId}`)
            .then((res) => {
                const tmp_variations = [...res.data[1]];

                setUploadedImg(res.data?.[0].image);
                seteditItemModal({
                    details: res?.data?.[0],
                    variations: tmp_variations,
                });
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
            });
        setIsLoading(false);
    };

    // EDIT

    const handleEditSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const formData = Object.values(e.target).reduce((obj, field) => {
            if (field.type === "checkbox") {
                obj[field.name] = field.checked === true ? true : null;
            } else {
                obj[field.name] = field.value;
            }
            return obj;
        }, {});

        setIsLoading(true);

        try {
            // update product details
            const productRes = await mobileAxios(
                `menu/${editItemModal?.details?._id}`,
                {
                    method: "PATCH",
                    data: {
                        adminId: userState?.admin_id_mobile,
                        category: formData.category,
                        order: formData.order,
                        image: uploadedImg,
                        name: formData.name,
                        in_stock: formData.in_stock,
                        price: formData.price,
                        recommended: formData.recommended,
                        contain_milk: formData.with_milk,
                    },

                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );

            const newVarData: any = {};
            Object.keys(formData).forEach((k) => {
                if (k.includes("new")) {
                    newVarData[k?.split("-new")[0]?.split("variation-")[1]] =
                        formData[k];
                }
            });

            // add new variation
            let newVarRes = { data: "no" };
            if (newVarData?.name) {
                newVarRes = await mobileAxios(`menu/variation`, {
                    method: "POST",
                    data: {
                        adminId: userState?.admin_id_mobile,
                        itemId: editItemModal?.details?._id,
                        title: newVarData.name,
                        ...newVarData,
                    },

                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                });
            }

            if (
                productRes.data === "token error" ||
                newVarRes.data === "token error"
            ) {
                throw new Error(productRes.data || newVarRes.data);
            }
            if (!productRes.data || !newVarRes.data) {
                throw new Error("not found");
            }
            fetchData();
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        openProductDetails(editItemModal?.details?._id);
        setNewVariationOpen(false);
        setUploadedImg(undefined);
        setIsLoading(false);
    };

    const updateVariation = async (e: any) => {
        const name: string | null | undefined = e.target?.name;
        const id: string | undefined = name?.split("-")[2];

        if (id === "new") {
            return;
        }

        let variation;
        if (id !== "new") {
            variation = editItemModal?.variations.find(
                (variation) => variation._id === id
            );
        }

        setIsLoading(true);

        try {
            mobileAxios.patch(
                `menu/variation/${id}`,
                {
                    adminId: userState?.admin_id_mobile,
                    title: name?.includes("name")
                        ? e.target.value
                        : variation.name,
                    price: name?.includes("price")
                        ? e.target.value
                        : variation.price,
                    type: name?.includes("type")
                        ? e.target.value
                        : variation.type,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                    },
                }
            );
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        setIsLoading(false);
    };

    const deleteVariation = async (id: string) => {
        setIsLoading(true);

        try {
            await mobileAxios(`menu/variation/${id}`, {
                method: "DELETE",
                data: {
                    adminId: userState?.admin_id_mobile,
                },
                headers: {
                    Authorization: `Bearer ${userState?.admin_login_token_mobile}`,
                },
            });
        } catch (err: any) {
            console.error(err);
            toast.error(
                `An error has occured! ${err.message}`,
                toast_error_settings
            );
        }

        openProductDetails(editItemModal?.details?._id);
    };

    return (
        <>
            {isLoading && <Loader withBg={true} />}
            <div className="h-fit mb-8 flex gap-8 items-center ">
                <h2 className="font-bold text-2xl">Menu items:</h2>

                <button
                    onClick={toggleAddItemModal}
                    className="px-8 py-3 flex items-center justify-center border"
                >
                    + Add Item
                </button>

                <div className="input-row max-w-xs mb-0 ml-auto">
                    <input
                        type="text"
                        onChange={onSearchTextChange}
                        className="w-fit !py-2"
                        placeholder="Search "
                    />
                </div>
            </div>

            {rows.length > 0 && (
                <Box sx={{ width: "100%" }}>
                    <Paper sx={{ width: "100%", mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                    onSelectAllClick={() => {}}
                                />
                                <TableBody>
                                    {rows
                                        .sort(getComparator(order, orderBy))
                                        .filter((item: any) => {
                                            return (
                                                item.name
                                                    ?.toLowerCase()
                                                    ?.includes(
                                                        inputSearchText
                                                    ) ||
                                                item.price
                                                    ?.toString()
                                                    // ?.toLowerCase()
                                                    ?.includes(
                                                        `${inputSearchText.toString()}`
                                                    ) ||
                                                item.recommended
                                                    ?.toLowerCase()
                                                    ?.includes(
                                                        inputSearchText
                                                    ) ||
                                                item.category
                                                    ?.toLowerCase()
                                                    ?.includes(
                                                        inputSearchText
                                                    ) ||
                                                item.status
                                                    ?.toLowerCase()
                                                    ?.includes(inputSearchText)
                                            );
                                        })
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TableCell>
                                                        <div className="flex gap-3 items-center">
                                                            <img
                                                                src={
                                                                    MEDIA_URL +
                                                                    row.image
                                                                }
                                                                alt=""
                                                                className="max-w-full h-16 w-16 object-contain bg-gray-100"
                                                            />
                                                            {row.name}
                                                        </div>
                                                    </TableCell>

                                                    <TableCell>
                                                        $ {row.price}
                                                    </TableCell>
                                                    {/* <TableCell>
                                                        {dayjs(
                                                            row.created_date
                                                        ).format(
                                                            "ddd DD MMM YYYY hh:mm A"
                                                        )}
                                                    </TableCell> */}

                                                    <TableCell>
                                                        {row.recommended
                                                            ? row.recommended
                                                            : "false"}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.category &&
                                                            row.category}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.in_stock == "true"
                                                            ? "IN STOCK"
                                                            : "OUT OF STOCK"}
                                                    </TableCell>

                                                    <TableCell>
                                                        <MUIButton
                                                            variant="outlined"
                                                            onClick={() => {
                                                                openProductDetails(
                                                                    row.id
                                                                );
                                                            }}
                                                        >
                                                            EDIT ITEM
                                                        </MUIButton>
                                                        <br />

                                                        <MUIButton
                                                            className="!block !mt-2"
                                                            variant="outlined"
                                                            color="error"
                                                            onClick={() =>
                                                                setDeleteModal(
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            DELETE ITEM
                                                        </MUIButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            )}

            {/* Edit Item  */}
            <Modal
                size="5xl"
                isOpen={editItemModal !== null}
                toggleModal={() => seteditItemModal(null)}
            >
                <form className="mt-6" onSubmit={handleEditSubmit}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedImg}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="name"
                            type="text"
                            placeholder="Name *"
                            defaultValue={editItemModal?.details?.name}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="order"
                            type="number"
                            placeholder="Order *"
                            defaultValue={editItemModal?.details?.order}
                            required
                        />
                    </div>

                    <div className="input-row">
                        <select
                            name="category"
                            id="category"
                            defaultValue={editItemModal?.details?.category}
                        >
                            {allCategories.map((category: any) => (
                                <option
                                    key={category._id}
                                    value={category._id}
                                    // selected={
                                    //     category._id ===
                                    //     editItemModal?.details?.category
                                    // }
                                >
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="input-row">
                        <select
                            name="in_stock"
                            id="in_stock"
                            defaultValue={editItemModal?.details?.in_stock}
                        >
                            <option value="true">In Stock</option>
                            <option value="false">Out of Stock</option>
                        </select>
                    </div>

                    <div className="input-wrap space-x-4">
                        <label>Price:</label>

                        <div className="input-wrap w-28 inline-flex items-center gap-2">
                            <span className="text-xl">$</span>
                            <input
                                className="flex-1 text-center"
                                name={`price`}
                                type="number"
                                step="any"
                                placeholder="Price *"
                                defaultValue={editItemModal?.details?.price}
                                required
                            />
                        </div>
                    </div>

                    <div className="flex gap-8 mt-4 input-center ">
                        <div className=" flex  gap-4 items-center">
                            <label htmlFor="recommended">Recommended </label>
                            <input
                                id="recommended"
                                name="recommended"
                                type="checkbox"
                                defaultChecked={
                                    editItemModal?.details?.recommended
                                }
                            />
                        </div>

                        <div className=" flex gap-4 input-center">
                            <label htmlFor="with_milk">With milk </label>
                            <input
                                id="with_milk"
                                name="with_milk"
                                type="checkbox"
                                defaultChecked={
                                    editItemModal?.details?.contain_milk?.toString() ===
                                    "true"
                                }
                            />
                        </div>
                    </div>

                    <hr className="my-4" />

                    <div className="">
                        <h4 className="mb-4 font-semibold text-xl">
                            Variations:{" "}
                        </h4>
                        <ul className="list-style-disc pl-6">
                            {editItemModal?.variations.map(
                                (variation: any, index: number) => (
                                    <>
                                        <li
                                            key={variation._id}
                                            className="flex items-center justify-between gap-4 pr-12"
                                        >
                                            <div className="input-wrap space-x-4 flex items-center  ">
                                                <label
                                                    htmlFor={`color-${index}`}
                                                >
                                                    Name:
                                                </label>
                                                <input
                                                    className="flex-1 inline-block"
                                                    name={`variation-name-${variation._id}`}
                                                    type="text"
                                                    placeholder="Name *"
                                                    onBlur={updateVariation}
                                                    defaultValue={
                                                        variation.title
                                                    }
                                                    required
                                                />
                                            </div>

                                            <div className="input-wrap space-x-4 ">
                                                <label
                                                    htmlFor={`price-${index}`}
                                                >
                                                    Price:
                                                </label>

                                                <div className="input-wrap w-28 inline-flex items-center gap-2">
                                                    <span className="text-xl">
                                                        $
                                                    </span>
                                                    <input
                                                        className="flex-1 text-center"
                                                        name={`variation-price-${variation._id}`}
                                                        onBlur={updateVariation}
                                                        type="number"
                                                        step="any"
                                                        placeholder="Price *"
                                                        defaultValue={
                                                            variation?.price
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="input-wrap items-center flex gap-4">
                                                <p>Type:</p>

                                                <input
                                                    className="flex-1 text-center"
                                                    name={`variation-type-${variation._id}`}
                                                    onBlur={updateVariation}
                                                    type="text"
                                                    placeholder="addons | ice | milk | hot drink | cold drink"
                                                    defaultValue={
                                                        variation?.type
                                                    }
                                                    required
                                                />
                                            </div> */}

                                            <div className="w-8 absolute right-0 top-0">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        deleteVariation(
                                                            variation._id
                                                        )
                                                    }
                                                    className="block mt-2 text-red-500"
                                                >
                                                    <AiFillDelete />
                                                </button>
                                            </div>
                                        </li>
                                        <br />
                                    </>
                                )
                            )}

                            {newVariationOpen === true && (
                                <li className="flex justify-between gap-4 mb-6 pr-12">
                                    <div className="input-wrap space-x-4 flex items-center">
                                        <label htmlFor={`namel-new`}>
                                            Name:
                                        </label>
                                        <input
                                            className="flex-1 inline-block"
                                            name={`variation-name-new`}
                                            type="text"
                                            placeholder="Name *"
                                            onBlur={updateVariation}
                                            required
                                        />
                                    </div>

                                    <div className="input-wrap space-x-4">
                                        <label>Price:</label>

                                        <div className="input-wrap w-28 inline-flex items-center gap-2">
                                            <span className="text-xl">$</span>
                                            <input
                                                className="flex-1 text-center"
                                                name={`variation-price-new`}
                                                onBlur={updateVariation}
                                                type="number"
                                                step="any"
                                                placeholder="Price *"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="input-wrap items-center flex gap-4">
                                        <p>Type:</p>

                                        <input
                                            className="flex-1 text-center"
                                            name={`variation-type-new`}
                                            onBlur={updateVariation}
                                            type="text"
                                            placeholder="addons | ice | milk | hot drink | cold drink"
                                            required
                                        />
                                    </div> */}
                                </li>
                            )}
                        </ul>

                        <div className="h-fit">
                            <button
                                type={newVariationOpen ? "submit" : "button"}
                                onClick={() =>
                                    !newVariationOpen &&
                                    setNewVariationOpen((p) => !p)
                                }
                                className="h-full min-h-[50px] w-full flex items-center justify-center border"
                            >
                                + Add Variation
                            </button>
                        </div>
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={() => seteditItemModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>

            {/* Add Item */}
            <Modal
                size="3xl"
                isOpen={addItemModal}
                toggleModal={toggleAddItemModal}
            >
                <form className="mt-6" onSubmit={handleAddNewProduct}>
                    <div className="input-row">
                        <Dropzone
                            defaultValue={uploadedImg}
                            handleIconUpload={(src: string) =>
                                setUploadedImg(src)
                            }
                            previewClass="w-full"
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="name"
                            type="text"
                            placeholder="Name *"
                            required
                        />
                    </div>

                    <div className="input-row">
                        <input
                            name="order"
                            type="number"
                            placeholder="Order *"
                            required
                        />
                    </div>

                    <div className="input-row">
                        <select name="category" id="category">
                            {allCategories.map((category: any) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="input-row">
                        <select
                            name="in_stock"
                            id="in_stock"
                            defaultValue="true"
                        >
                            <option value="true">In Stock</option>
                            <option value="false">Out of Stock</option>
                        </select>
                    </div>

                    {/* <div className=" flex my-3  gap-4 items-center">
                     <input
                                id="stock"
                                name="stock"
                                type="checkbox"
                            />
                            <label htmlFor="stock">In Stock </label>
                           
                        </div> */}
                    <div className="input-wrap space-x-4">
                        <label>Price:</label>

                        <div className="input-wrap w-28 inline-flex items-center gap-2">
                            <span className="text-xl">$</span>
                            <input
                                className="flex-1 text-center"
                                name={`price`}
                                type="number"
                                step="any"
                                placeholder="Price *"
                                required
                            />
                        </div>
                    </div>

                    <div className="flex gap-8 mt-4 input-center ">
                        <div className=" flex  gap-4 items-center">
                            <label htmlFor="recommended">Recommended </label>
                            <input
                                id="recommended"
                                name="recommended"
                                type="checkbox"
                            />
                        </div>

                        <div className=" flex gap-4 input-center">
                            <label htmlFor="with_milk">With milk </label>
                            <input
                                id="with_milk"
                                name="with_milk"
                                type="checkbox"
                            />
                        </div>
                    </div>

                    <div className="flex space-x-4">
                        <Button
                            color="transparent"
                            className="ml-auto mt-8"
                            type="button"
                            onClick={toggleAddItemModal}
                        >
                            Cancel
                        </Button>
                        <Button className="ml-auto mt-8" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal>
            {/* Delete Item  */}
            <Modal
                isOpen={deleteModal !== null}
                toggleModal={() => setDeleteModal(null)}
            >
                <form className="mt-6" onSubmit={handleItemDelete}>
                    <h4 className="text-center text-lg font-[600]">
                        Are you sure you want to delete this Item?
                    </h4>
                    <div className="flex justify-center space-x-4">
                        <Button
                            className=" mt-8"
                            type="button"
                            onClick={() => setDeleteModal(null)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className=" mt-8 !bg-red-600 !border-red-600"
                            type="submit"
                        >
                            Delete
                        </Button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default AdminMenuByCountry;
